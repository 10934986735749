
/**
 * @name: 数据统计-设备订单量统计
 * @author: itmobai
 * @date: 2024-08-01 10:50
 * @description： 数据统计-设备订单量统计
 * @update: 2024-08-01 10:50
 */
import { Vue, Component } from "vue-property-decorator"
import { deviceOrderPageApi, deviceOrderNumApi, deviceOrderExportApi } from '@/apis/statistics/device-order'
import { IDeviceOrderParams, IDeviceOrder } from '@/apis/statistics/device-order/types'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from '@/utils/common'
import Big from 'big.js'

@Component({})
export default class deviceOrder extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IDeviceOrder[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IDeviceOrderParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: '设备编号',
        prop: 'deviceSn',
        align: 'left',
        search: true
      },
      // {
      //   label: '所属代理商',
      //   prop: 'agentName',
      //   align: 'center',
      //   search: true
      // },
      {
        label: '累计订单数量',
        prop: 'accumulatedOrderQty',
        align: 'center',
        // @ts-ignore
        default: 0
      },
      {
        label: '累计订单总时长(分钟)',
        prop: 'useDurationSum',
        align: 'center',
        slot: true
      },
      {
        label: '累计订单金额(元)',
        prop: 'payPrice',
        align: 'center',
        // @ts-ignore
        default: 0
      },
      {
        label: '激活时间',
        prop: 'activateTime',
        align: 'center',
        search: true,
        type: 'daterange'
      },
    ]
  }

  accumulatedOrderQty = 0

  useDurationSum = 0

  payPrice = 0

  getList() {
    this.tableLoading = true

    const query: IDeviceOrderParams = deepCopy(this.queryParam)
    if (query.activateTime && query.activateTime.length == 2) {
      query.startTime = query.activateTime[0]
      query.endTime = query.activateTime[1]
    } else {
      query.startTime = ''
      query.endTime = ''
    }
    delete query.activateTime

    deviceOrderNumApi(query).then(e => {
      if (e && e.data) {
        this.accumulatedOrderQty = e.data.accumulatedOrderQty
        this.useDurationSum = e.data.useDurationSum
        this.payPrice = e.data.payPrice
      }
    })
    deviceOrderPageApi(query).then(e => {
      if (e && e.data) {
        this.tableData = e.data.list
        this.tableTotal = e.data.total
        this.tableLoading = false
      }
    })
  }

  exportExcel () {
    const query: IDeviceOrderParams = deepCopy(this.queryParam)
    if (query.activateTime && query.activateTime.length == 2) {
      query.startTime = query.activateTime[0]
      query.endTime = query.activateTime[1]
    } else {
      query.startTime = ''
      query.endTime = ''
    }
    delete query.activateTime
    deviceOrderExportApi(query).then(e => {
      if (e && e.data) {
        exportFile(e.data, '设备订单量统计.xlsx')
      }
    })
  }

  getUseDuration (seconds: number) {
    if (!seconds) {
      return 0
    }
    if (seconds >= (5 * 60)) {
      // 向上
      return Math.ceil(new Big(seconds).div(60).toNumber())
    } else {
      // 向下
      return Math.floor(new Big(seconds).div(60).toNumber())
    }
  }

  created() {
    this.getList()
  }
}
